// # region Actions
import {
  FETCH_DELIVERY_TRACKING_UPDATES,
  UPDATE_DELIVERY_TRACKING_UPDATES,
  EXPAND_DELIVERY_TRACKING_UPDATES
} from './deliveryTrackingUpdates-actions'
// # endregion
import { getItemsToDisplay, getTrackingEventSectionData } from './deliveryTrackingUpdates-functions'
import { LoadingStatus } from '../../redux/constants'
import { EllipsisOutlined } from '@ant-design/icons'

const initialState = {
  deliveryTrackingUpdatesLoadingStatus: LoadingStatus.idle,
  error: null,
  itemsToDisplay: [],
  allEventsAndButtons: [],
  showingIndexSet: {},
  warnSymbol: undefined,
  expanded: []
}

export default function deliveryTrackingUpdatesReducer (
  state = initialState,
  action
) {
  // eslint-disable-next-line
  switch (action.type) {
    case FETCH_DELIVERY_TRACKING_UPDATES: {
      return {
        ...state,
        deliveryTrackingUpdatesLoadingStatus: LoadingStatus.loading,
        warnSymbol: <EllipsisOutlined />
      }
    }
    case UPDATE_DELIVERY_TRACKING_UPDATES: {
      if (action.payload?.hasError) {
        return {
          ...state,
          error: { errorContent: action.payload?.errorContent },
          deliveryTrackingUpdatesLoadingStatus: LoadingStatus.failed,
          warnSymbol: undefined
        }
      }
      const trackingEventSectionData = getTrackingEventSectionData(action.payload)
      return {
        ...state,
        deliveryTrackingUpdatesLoadingStatus: LoadingStatus.succeeded,
        warnSymbol: trackingEventSectionData.warnIcon,
        itemsToDisplay: trackingEventSectionData.itemsToDisplay,
        allEventsAndButtons: trackingEventSectionData.allEventsAndButtons,
        showingIndexSet: trackingEventSectionData.showingIndexSet
      }
    }
    case EXPAND_DELIVERY_TRACKING_UPDATES: {
      if (!action.payload.childNodes || action.payload.childNodes.length < 3) {
        break
      }
      const childNodesArr = action.payload.childNodes.split(':')
      const allEventsAndButtons = state.allEventsAndButtons.slice()
      allEventsAndButtons[childNodesArr[0] - 1].expanded = !allEventsAndButtons[childNodesArr[0] - 1].expanded
      const showingIndexSet = { ...state.showingIndexSet }
      // Flip whether the show/hide indexes show or hide
      for (let index = parseInt(childNodesArr[0]); index <= parseInt(childNodesArr[1]); index++) {
        showingIndexSet[index] = !showingIndexSet[index]
      }
      const itemsToDisplay = getItemsToDisplay(allEventsAndButtons, showingIndexSet)
      return {
        ...state,
        allEventsAndButtons: allEventsAndButtons,
        itemsToDisplay: itemsToDisplay,
        showingIndexSet: showingIndexSet
      }
    }
  }
  return state
}
