
import React from 'react'
import PropTypes from 'prop-types'
// # region Components
import { Divider } from 'antd'
import BrandingBar from './BrandingBar'
// # endregion

/**
 * A header with optional branding
 *
 * @param brandingName The name of the company who is branding the site
 * @param brandingImage The link to an icon of the company who is branding the site
 * @param brandingColor A color to brand the header, which shows as a 5px bar beneath the actual header
 * @returns A header bar for the page
 */
const Header = function (props) {
  return (
    <div data-testid='page-header-root' className='page-header-root'>
      <div className='page-header-main-section'>
        <div className='page-header-main-section-content'>
          <img
            className='page-header-redwood-logo'
            src={`${process.env.PUBLIC_URL}/Redwood Logo White.png`}
            alt='Redwood'
          />
          {
            props.brandingImage && (
              <>
                <Divider
                  data-testid='page-header-divider'
                  className='page-header-divider'
                  type='vertical'
                />
                <img
                  data-testid='page-header-carrier-logo'
                  className='page-header-carrier-logo'
                  src={props.brandingImage}
                  alt={props.brandingName}
                />
              </>
            )
          }
        </div>
      </div>
      <BrandingBar color={props.brandingColor} />
      <img
        className='page-header-background-image'
        src={`${process.env.PUBLIC_URL}/Background Banner-03.png`}
        alt='Background'
      />
    </div>
  )
}

Header.defaultProps = {
  brandingColor: '#BF1E22'
}
Header.propTypes = {
  brandingImage: PropTypes.string,
  brandingName: PropTypes.string,
  brandingColor: PropTypes.string
}

export default Header
