import React from 'react'
import PropTypes from 'prop-types'
import { EmptyUpdates } from '../../assets/icons/EmptyUpdates'
import { ContactPropType } from '../../constants'
// # region Components
import AddressText from '../../components/AddressText'
import { Col, Divider, Empty, Row } from 'antd'
import { PhoneIcon } from '../../assets/icons/Phone'
import { MailIcon } from '../../assets/icons/Mail'
// # endregion

/**
 * The contact info section. Shows the address, phone, and email of contacts
 * for origin and terminal carriers
 *
 * @param props.stops The stops of the shipment
 * @returns The contact info section
 */
const ContactInformationSection = function (props) {
  if (!props.contacts || props.contacts.length === 0) {
    return (
      <Empty
        data-testid='contact-info-section-root-empty'
        image={
          <EmptyUpdates className='tracking-updates-events-section-empty-image' />
      }
        description='No Contact Information is Available'
      />
    )
  }
  return (
    <>
      {props.contacts.map((contact, contactIdx) => (
        <div
          data-testid={`contact-info-section-contact-${contactIdx}`}
          key={contactIdx}
          className='contact-information-section-root'
        >
          {contactIdx !== 0 && <Divider className='contact-information-section-divider' />}
          <Row gutter={[0, 8]} align='middle'>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <div className='contact-information-section-contact-title'>
                {contact.companyName}
              </div>
              <AddressText address={contact.address} />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Row
                gutter={[0, 8]}
                span={24}
              >
                {contact.phoneNumbers && contact.phoneNumbers.reduce(function (filteredPhones, phoneNumber) {
                  if (phoneNumber) {
                    filteredPhones.push(phoneNumber)
                  }
                  return filteredPhones
                }, []).map((phoneNumber, idxPhone) => (
                  <Col key={idxPhone} span={24}>
                    <Row className='contact-information-section-contact'>
                      <PhoneIcon className='contact-information-section-contact-icon' />
                      <a aria-label={`phone: ${phoneNumber}`} href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                    </Row>
                  </Col>
                ))}
                {contact.emailAddress &&
                  <Col span={24}>
                    <Row className='contact-information-section-contact'>
                      <MailIcon className='contact-information-section-contact-icon' />
                      <a aria-label={`email: ${contact.emailAddress}`} href={`mailto:${contact.emailAddress}?&subject=Delivery inquiry`}>
                        {contact.emailAddress}
                      </a>
                    </Row>
                  </Col>}
              </Row>
            </Col>
          </Row>
        </div>
      ))}
    </>
  )
}

ContactInformationSection.propTypes = {
  contacts: PropTypes.arrayOf(ContactPropType)
}

export default ContactInformationSection
