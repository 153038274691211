import React from 'react'
import PropTypes from 'prop-types'

/**
 * A full width branding bar of a provided color
 *
 * @param color The color of the branding bar
 * @param height The thickness of the branding bar
 * @returns A full width branding bar of a provided color
 */
const BrandingBar = function (props) {
  return (
    <div
      data-testid='branding-bar'
      className='branding-bar'
      style={{ height: props.height, backgroundColor: props.color }}
    />
  )
}

BrandingBar.defaultProps = {
  height: '5px'
}
BrandingBar.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.string
}

export default BrandingBar
