import React from 'react'
import PropTypes from 'prop-types'
// # region Components
import BrandingBar from '../components/BrandingBar'
// # endregion

/**
 * A footer with an optional branding bar shown at the bottom of the tracker
 *
 * @param props.brandingColor A color to brand the footer, which shows as a 15px bar beneath the actual footer
 * @returns A footer bar for the page
 */
const Footer = function (props) {
  return (
    <div data-testid='page-footer-root' className='page-footer-root'>
      <div className='page-footer-main-section'>
        <img
          className='page-footer-redwood-logo'
          src={`${process.env.PUBLIC_URL}/Redwood Logo White.png`}
          alt='Redwood'
        />
        <div className='page-footer-text-div'>
          <p className='page-footer-text'>
            1765 N. Elston Ave, Suite 216
          </p>
          <p className='page-footer-text'>
            Chicago, IL 60642
          </p>
        </div>
      </div>
      <BrandingBar height='15px' color={props.brandingColor} />
    </div>
  )
}

Footer.defaultProps = {
  brandingColor: '#BF1E22'
}
Footer.propTypes = {
  brandingColor: PropTypes.string
}

export default Footer
