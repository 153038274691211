import React from 'react'
import PropTypes from 'prop-types'
// # region Components
import { Alert, Spin } from 'antd'
// # endregion
import { LoadingStatus } from '../redux/constants'

/**
 * A component which handles loading content. It shows a spinner while loading,
 * content if the data was loaded successfully, or an alert if the data was not
 * loaded successfully
 *
 * @returns The component loader component
 */
const ComponentLoader = function (props) {
  switch (props.loadingStatus) {
    case LoadingStatus.idle:
    // eslint-disable-next-line no-fallthrough
    case LoadingStatus.loading:
      return <Spin data-testid='loading-spinner' style={props.loadingSpinnerTopMargin && { marginTop: props.loadingSpinnerTopMargin }} tip={props.loadingText} size='large' />
    case LoadingStatus.succeeded:
      return props.successComponent
    case LoadingStatus.failed:
    // eslint-disable-next-line no-fallthrough
    default:
      if (props.consoleErrorMessage) {
        console.debug(props.consoleErrorMessage)
      }
      if (props.errorStatus === 204) {
        return (
          <Alert data-testid='failed-load-alert-204' message='Tracking information not found' type='error' />
        )
      } else {
        return (
          <Alert data-testid='failed-load-alert-other' message={props.failedLoadText} type='error' />
        )
      }
  }
}

ComponentLoader.propTypes = {
  loadingSpinnerTopMargin: PropTypes.string,
  loadingStatus: PropTypes.oneOf(Object.keys(LoadingStatus).map((key) => LoadingStatus[key])),
  loadingText: PropTypes.string,
  successComponent: PropTypes.element,
  consoleErrorMessage: PropTypes.string,
  errorStatus: PropTypes.number,
  failedLoadText: PropTypes.string
}

export default ComponentLoader
