export const FETCH_COMPANY_BRANDING = 'FETCH_COMPANY_BRANDING'
export const UPDATE_SITE_BRANDING = 'UPDATE_SITE_BRANDING'

export const fetchCompanyBranding = (tenantShortName) => ({
  type: FETCH_COMPANY_BRANDING,
  payload: { tenantShortName }
})

export const updateSiteBranding = (branding) => ({
  type: UPDATE_SITE_BRANDING,
  payload: {
    ...branding
  }
})
