import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
// # region Redux
import { useDispatch, useSelector } from 'react-redux'
import { fetchDeliveryDetails } from '../redux/deliveryDetails-actions'
import { fetchDeliveryTrackingUpdates } from '../redux/deliveryTrackingUpdates-actions'
// # endregion
// # region Components
import ComponentLoader from '../../components/ComponentLoader'
import DeliverySummary from './DeliverySummary'
// # endregion

/**
 * A component which handles showing loading/content. The content includes
 * nearly everything between the header and footer
 *
 * @returns The delivery summary component
 */
const DeliverySummaryLoader = function (props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchDeliveryDetails(props.tenantId, props.shipmentId, props.trackingNumber))
    dispatch(fetchDeliveryTrackingUpdates(props.tenantId, props.shipmentId, props.trackingNumber))
  }, [props.tenantId, props.shipmentId, props.trackingNumber, dispatch])

  const { deliveryDetailsLoadingStatus, error, data } = useSelector(
    (state) => state.deliveryDetails
  )
  const updatesData = useSelector(
    (state) => state.deliveryTrackingUpdates
  )
  
  return (
    <ComponentLoader
      loadingSpinnerTopMargin='120px'
      loadingStatus={deliveryDetailsLoadingStatus}
      loadingText='Loading...'
      successComponent={
        <DeliverySummary
          shipmentId={data?.shipmentId}
          trackingNumber={props.trackingNumber}
          shipmentStatus={data?.status}
          eta={data?.eta}
          steps={data?.steps}
          warnSymbol={updatesData?.warnSymbol}
          carriers={data?.carriers}
          contacts={data?.contacts}
        />
      }
      consoleErrorMessage={error?.errorContent?.data?.message}
      errorStatus={error?.errorContent?.status}
      failedLoadText={error?.errorContent?.message || 'Something went wrong. Try again later.'}
    />
  )
}

DeliverySummaryLoader.propTypes = {
  tenantId: PropTypes.string,
  shipmentId: PropTypes.string,
  trackingNumber: PropTypes.string
}

export default DeliverySummaryLoader
