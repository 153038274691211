// # region Actions
import {
  FETCH_COMPANY_BRANDING,
  UPDATE_SITE_BRANDING
} from './branding-actions'
// # endregion
import { LoadingStatus } from '../constants'

const initialState = {
  brandingLoadingStatus: LoadingStatus.idle,
  error: null,
  data: undefined
}

export default function brandingReducer (state = initialState, action) {
  // eslint-disable-next-line
  switch (action.type) {
    case FETCH_COMPANY_BRANDING: {
      return {
        ...state,
        brandingLoadingStatus: LoadingStatus.loading
      }
    }
    case UPDATE_SITE_BRANDING: {
      if (action.payload?.hasError) {
        return {
          ...state,
          error: { errorContent: action.payload?.errorContent },
          brandingLoadingStatus: LoadingStatus.failed
        }
      }
      return {
        ...state,
        brandingLoadingStatus: LoadingStatus.succeeded,
        ...action.payload
      }
    }
  }
  return state
}
