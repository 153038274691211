import React from 'react';
import PropTypes from 'prop-types';
import {
  get24HrTime,
  getConvertedDate24Str,
  getConvertedDateObj,
  getSentenceCase,
  getTimeZone,
} from '../../common-functions';
import { ShipmentStatus, ShipmentStatusPropType, StepPropType } from '../../constants';
// # region Components
import AddressText from '../../components/AddressText';
import LabeledData from '../../components/LabeledData';
import { Calendar } from '../../assets/icons/Calendar';
import { CheckedCircleStep } from '../../assets/icons/CheckedCircleStep';
import { CircleStep } from '../../assets/icons/CircleStep';
import { FindLocation } from '../../assets/icons/FindLocation';
import { DeliveryTruckLoad } from '../../assets/icons/DeliveryTruckLoad';
import { TruckStep } from '../../assets/icons/TruckStep';
import { TruckStepComplete } from '../../assets/icons/TruckStepComplete';
import { TruckStepError } from '../../assets/icons/TruckStepError';
import { Card, Col, Divider, Row, Steps } from 'antd';
import { StopType } from '../../constants';
import { getConvertedDateTimeObj } from '../../common-functions';
import { DateTime } from 'luxon';
// # endregion
const { Step } = Steps;

const stepColorGreen = '#6bbd46';
const stepColorBlue = '#096dd9';
const stepColorRed = '#cf1322';

/**
 * The delivery details card. Shows some overal data, and the
 * progress graphic (step control).
 *
 * @param props.steps The steps of the step control
 * @param props.shipmentIdentifiers The shipment identifier data
 * @param props.latestTrackingUpdate The latest tracking update
 * @returns The delivery details card
 */
const DeliveryDetails = function (props) {
  // enum of statuses according to the step control
  const stepStatuses = {
    error: 'error',
    finish: 'finish',
    process: 'process',
    wait: 'wait',
  };
  // Status/ status text
  let statusText = props.shipmentStatus?.description;
  if (statusText === undefined || statusText === null || statusText === '') {
    statusText = 'Unavailable';
  }
  let stepStatus = '';
  if (props.shipmentStatus?.type === ShipmentStatus.Delivered) {
    const arrivalTimeStr = getArrivalTime(props?.stops);
    if (arrivalTimeStr !== '') {
      statusText += ` (${arrivalTimeStr})`;
    }
  }
  // Initial values for step control values, to be set by switch statement
  let statusColor = 'black';
  let steps = [];
  const descriptions = [];
  if (props?.steps?.length > 0) {
    for (const step of props.steps) {
      descriptions.push(step.location?.address ? <AddressText address={step.location?.address} /> : 'Unavailable');
    }
  }
  // Set step control values
  switch (props.shipmentStatus?.type) {
    case ShipmentStatus.EnRoute:
      stepStatus = stepStatuses.process;
      statusColor = 'black';
      /* eslint-disable react/jsx-key */
      steps = [
        <CheckedCircleStep style={{ color: stepColorBlue }} data-testid="step1" />,
        <TruckStep style={{ color: stepColorBlue }} data-testid="step2" />,
        <CircleStep data-testid="step3" />,
      ];
      /* eslint-enable react/jsx-key */
      break;
    case ShipmentStatus.Error:
      // A stepStatus of 'error' hardcodes certain values which we are trying to override
      // So just set this to process instead
      stepStatus = stepStatuses.process;
      statusColor = 'red';
      /* eslint-disable react/jsx-key */
      steps = [
        <CheckedCircleStep style={{ color: stepColorBlue }} data-testid="step1" />,
        <TruckStepError style={{ color: stepColorRed }} data-testid="step2" />,
        <CircleStep data-testid="step3" />,
      ];
      /* eslint-enable react/jsx-key */
      break;
    case ShipmentStatus.Delivered:
      stepStatus = stepStatuses.finish;
      statusColor = 'green';
      /* eslint-disable react/jsx-key */
      steps = [
        <CheckedCircleStep style={{ color: stepColorGreen }} data-testid="step1" />,
        <TruckStepComplete style={{ color: stepColorGreen }} data-testid="step3" />,
      ];
      /* eslint-enable react/jsx-key */
      break;
    case ShipmentStatus.NotStarted:
      stepStatus = stepStatuses.process;
      statusColor = 'black';
      /* eslint-disable react/jsx-key */
      steps = [<TruckStep style={{ color: stepColorBlue }} data-testid="step1" />, <CircleStep data-testid="step3" />];
      /* eslint-enable react/jsx-key */
      break;
    case ShipmentStatus.Unknown:
    default:
      stepStatus = stepStatuses.wait;
      statusColor = 'gray';
      /* eslint-disable react/jsx-key */
      steps = [<CircleStep data-testid="step1" />, <CircleStep data-testid="step3" />];
      /* eslint-enable react/jsx-key */
      break;
  }

  // The eta date comes back as a utc time (with trailing Z) but its actually a local time.
  // So calling toUTC then formatting will ignore the timezone conversion and just display as is
  // If the shipment is delivered, the delivered timestamp for the tracking event will be sent back
  // which is actually in utc so need to convert to local

  const destinationStep = props.steps.find((s) => s.stopType === StopType.Destination);
  const destinationTimezoneIdentifier = destinationStep?.location?.timezoneIdentifier;
  const etaDateTime =
    destinationTimezoneIdentifier && props.shipmentStatus?.type === ShipmentStatus.Delivered
      ? getConvertedDateTimeObj(props.eta, destinationTimezoneIdentifier)
      : DateTime.fromISO(props.eta)?.toUTC();
  const etaDisplayTime = etaDateTime ? etaDateTime.toFormat('EEEE M/d/yy HH:mm') : 'Unavailable';

  return (
    <Card className="delivery-details-root">
      <Row gutter={[0, 8]} align="middle">
        <Col
          xs={24}
          sm={24}
          md={{ offset: 1, span: 11 }}
          lg={{ offset: 1, span: 7 }}
          xl={{ offset: 1, span: 7 }}
          xxl={{ offset: 1, span: 7 }}
        >
          <LabeledData
            big
            icon={DeliveryTruckLoad}
            label="Shipment #"
            value={
              props.trackingNumber
                ? props.trackingNumber.length <= 8
                  ? `${props.shipmentNumber} / ${props.trackingNumber}`
                  : [`${props.shipmentNumber} /`, props.trackingNumber]
                : props.shipmentNumber
            }
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={{ offset: 1, span: 11 }}
          lg={{ offset: 1, span: 7 }}
          xl={{ offset: 1, span: 7 }}
          xxl={{ offset: 1, span: 7 }}
        >
          <LabeledData
            big
            icon={FindLocation}
            label="Status"
            value={getSentenceCase(statusText)}
            valueColor={statusColor}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={{ offset: 1, span: 11 }}
          lg={{ offset: 1, span: 7 }}
          xl={{ offset: 1, span: 7 }}
          xxl={{ offset: 1, span: 7 }}
        >
          <LabeledData big icon={Calendar} label="ETA" value={etaDisplayTime} />
        </Col>
      </Row>
      <Divider />
      <Steps labelPlacement="vertical" responsive status={stepStatus}>
        {steps.map((step, idx) => (
          <Step
            key={idx}
            className={
              idx === 0 &&
              (props.shipmentStatus?.type === ShipmentStatus.Delivered
                ? 'delivery-details-green-steps-line'
                : props.shipmentStatus?.type === ShipmentStatus.EnRoute ||
                  props.shipmentStatus?.type === ShipmentStatus.Error
                ? 'delivery-details-blue-steps-line'
                : '')
            }
            title={idx === 0 ? 'Origin' : idx === 1 && steps.length === 3 ? 'Current Location' : 'Destination'}
            icon={step}
            description={descriptions[idx]}
          />
        ))}
      </Steps>
    </Card>
  );
};

function getArrivalTime(stops) {
  if (!stops || stops.length < 2) {
    return '';
  }
  const arrivalTime = stops[stops.length - 1]?.stopStatus?.arrivalTime;
  if (!arrivalTime || arrivalTime === '') {
    return '';
  }
  const arrivalTimeObj = new Date(arrivalTime);
  const destinationTz = stops[stops.length - 1]?.location?.timezoneIdentifier;
  const nowTimeDestination = getConvertedDateObj(new Date(), destinationTz);
  const arrivalTimeDestination = getConvertedDateObj(arrivalTimeObj, destinationTz);
  let isSameDay = nowTimeDestination.getFullYear() === arrivalTimeDestination.getFullYear();
  if (isSameDay) {
    isSameDay = nowTimeDestination.getMonth() === arrivalTimeDestination.getMonth();
    if (isSameDay) {
      isSameDay = nowTimeDestination.getDate() === arrivalTimeDestination.getDate();
    }
  }
  if (!isSameDay) {
    return getConvertedDate24Str(arrivalTimeObj, destinationTz);
  }
  return `${get24HrTime(arrivalTimeDestination)} ${getTimeZone(arrivalTimeDestination)}`;
}

DeliveryDetails.propTypes = {
  deliveryNumber: PropTypes.string,
  shipmentStatus: ShipmentStatusPropType,
  eta: PropTypes.string, // Date
  steps: PropTypes.arrayOf(StepPropType),
};

export default DeliveryDetails;
