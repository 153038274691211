import React from 'react'
import PropTypes from 'prop-types'
import { CarrierPropType, ContactPropType, ShipmentStatusPropType, StepPropType } from '../../constants'
// # region Components
import DeliveryDetails from './DeliveryDetails'
import CarrierInformationSection from './CarrierInformationSection'
import ContactInformationSection from './ContactInformationSection'
import TrackingUpdatesEventsSectionLoader from './TrackingUpdatesEventsSectionLoader'
import { Collapse } from 'antd'
// # endregion
const { Panel } = Collapse

/**
 * A component which handles showing loading/content. The content includes
 * nearly everything between the header and footer
 *
 * @returns The delivery summary component
 */
const DeliverySummary = function (props) {
  return (
    <div data-testid='delivery-summary-root' className='delivery-summary-root'>
      <DeliveryDetails
        shipmentNumber={props.shipmentId}
        trackingNumber={props.trackingNumber}
        shipmentStatus={props.shipmentStatus}
        eta={props.eta}
        steps={props.steps}
      />
      <Collapse defaultActiveKey='0' accordion expandIconPosition='right'>
        <Panel header='Tracking Updates' extra={props.warnSymbol}>
          <TrackingUpdatesEventsSectionLoader />
        </Panel>
        <Panel header='Carrier Information'>
          <CarrierInformationSection carriers={props.carriers} trackingNumber={props.trackingNumber} shipmentNumber={props.shipmentId} />
        </Panel>
        <Panel header='Contact Information'>
          <ContactInformationSection contacts={props.contacts} />
        </Panel>
      </Collapse>
    </div>
  )
}

DeliverySummary.propTypes = {
  shipmentId: PropTypes.string,
  shipmentStatus: ShipmentStatusPropType,
  eta: PropTypes.string, // Date
  steps: PropTypes.arrayOf(StepPropType),
  warnSymbol: PropTypes.any, // undefined, '', or an element
  carriers: PropTypes.arrayOf(CarrierPropType),
  contacts: PropTypes.arrayOf(ContactPropType)
}

export default DeliverySummary
