import { combineReducers } from 'redux'
import brandingReducer from './branding/branding-reducer'
import deliveryDetailsReducer from '../Delivery/redux/deliveryDetails-reducer'
import deliveryTrackingUpdatesReducer from '../Delivery/redux/deliveryTrackingUpdates-reducer'
import env from './env/env-reducer'

const rootReducer = combineReducers({
  env,
  branding: brandingReducer,
  deliveryDetails: deliveryDetailsReducer,
  deliveryTrackingUpdates: deliveryTrackingUpdatesReducer
})

export default rootReducer
