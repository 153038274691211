import brandingSaga from './branding/branding-saga'
import deliveryDetailsSaga from '../Delivery/redux/deliveryDetails-saga'
import deliveryTrackingUpdatesSaga from '../Delivery/redux/deliveryTrackingUpdates-saga'

const rootSaga = [
  brandingSaga,
  deliveryDetailsSaga,
  deliveryTrackingUpdatesSaga
]

export default rootSaga
