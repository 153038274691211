import { rest } from 'msw'
import { ShipmentStatus } from '../constants'

const baseUrl = process.env.REACT_APP_RW_API_BASE_URL

export const handlers = [
  // Handles a GET /branding request
  rest.get(
    baseUrl + '/api/portal/customer/:tenantShortName/branding',
    (req, res, ctx) => {
      const { tenantShortName } = req.params
      if (tenantShortName === '0') {
        return res(
          ctx.status(400),
          ctx.delay(1000),
          ctx.json({
            message: '(Mock) Invalid Tracking ID used!'
          })
        )
      }
      let color1 = ''
      let color2 = ''
      let name = ''
      let logoUrl = ''
      switch (tenantShortName % 3) {
        case 0:
          color1 = '#BF1E22'
          color2 = '#096dd9'
          name = 'Big Department Store'
          logoUrl =
            'https://www.redwoodlogistics.com/wp-content/themes/redwood/static/wood.svg'
          break
        case 1:
          color1 = '#33ddaa'
          color2 = '#886aa4'
          name = `Tenant ${tenantShortName}`
          break
        case 2:
        default:
          color1 = '#886aa4'
          color2 = '#33ddaa'
          name = 'Customer'
          logoUrl = 'https://www.nvisia.com/hubfs/NV_Stacked.jpg#keepProtocol'
          break
      }
      return res(
        ctx.status(200),
        ctx.delay(200),
        ctx.json({
          tenantId: `${tenantShortName}-01`,
          name: name,
          colors: {
            primary: color1,
            secondary: color2
          },
          logoUrl: logoUrl
        })
      )
    }
  ),
  // Handles a GET /shipment request
  rest.get(
    baseUrl + '/api/portal/customer/:tenantId/shipment/:shipmentId',
    (req, res, ctx) => {
      const shipmentId = req.params.shipmentId
      const trackingNumber = req.url.searchParams.get('trackingNumber')
      if (shipmentId === '0') {
        return res(
          ctx.status(500),
          ctx.delay(1000),
          ctx.json({
            message: '(Mock) internal server error (500)'
          })
        )
      }
      // Denote parcel with p in shipment Id for MSW
      if (shipmentId.includes('p') && !trackingNumber) {
        return res(
          ctx.status(400),
          ctx.delay(100),
          ctx.json({
            message: 'Tracking update number is required for parcel shipments'
          })
        )
      }
      if (!shipmentId.includes('p') && trackingNumber) {
        return res(
          ctx.status(400),
          ctx.delay(100),
          ctx.json({
            message:
              'Tracking update number should not be provided for non-parcel shipments'
          })
        )
      }
      const responseObj = {}
      responseObj.shipmentId = shipmentId
      responseObj.steps = []
      responseObj.carriers = [
        {
          identifiers: [
            {
              type: 'SCAC',
              value: 'ShpC'
            },
            {
              type: 'Field 2',
              value: 'Value 2'
            },
            {
              type: 'Field 3',
              value: ''
            },
            {
              type: 'Field 4',
              value: null
            },
            {
              type: 'PRO#',
              value: '1351654881'
            },
            {
              type: 'Type',
              value: 'shipping'
            }
          ],
          name: 'Shippers Co, Inc.',
          contacts: [
            {
              companyName: 'Terminal Origin Carrier',
              firstName: null,
              lastName: null,
              phoneNumbers: null,
              emailAddress: 'carrier1@example.com',
              fax: null,
              address: {
                name: 'Origin place',
                lines: ['123 Test', '456 Test'],
                postalCode: '54321',
                city: 'Burbank',
                province: 'IL',
                countryCode: 'USA'
              }
            },
            {
              companyName: 'Terminal Destination Carrier',
              firstName: null,
              lastName: null,
              phoneNumbers: ['800-123-4567'],
              emailAddress: 'carrier2@example.com',
              fax: null,
              address: {
                name: 'Chicago Service Center',
                lines: ['7526 South State Rd'],
                postalCode: '12345',
                city: 'Cicero',
                province: 'ILL',
                countryCode: null
              }
            }
          ]
        },
        {
          identifiers: [
            {
              type: 'SCAC',
              value: 'AVRT'
            },
            {
              type: 'PRO#',
              value: '9476893010'
            },
            {
              type: 'Type',
              value: 'trucking'
            }
          ],
          name: 'Averitt Express, Inc.'
        },
        {
          identifiers: null,
          name: 'Blank Co.'
        }
      ]
      responseObj.contacts = [
        {
          companyName: 'Terminal Origin Carrier',
          firstName: null,
          lastName: null,
          phoneNumbers: null,
          emailAddress: 'carrier@example.com',
          fax: null,
          address: {
            name: null,
            lines: null,
            postalCode: '12345',
            city: 'Burbank',
            province: 'IL',
            countryCode: null
          }
        },
        {
          companyName: 'Terminal Destination Carrier',
          firstName: null,
          lastName: null,
          phoneNumbers: ['800-123-4567'],
          emailAddress: null,
          fax: null,
          address: {
            name: 'Chicago Service Center',
            lines: ['7526 South State Rd'],
            postalCode: '12345',
            city: 'Burbank',
            province: 'IL',
            countryCode: null
          }
        },
        {
          companyName: null,
          firstName: null,
          lastName: null,
          phoneNumbers: [],
          emailAddress: null,
          fax: null,
          address: {
            name: null,
            lines: [],
            postalCode: null,
            city: null,
            province: null,
            countryCode: null
          }
        }
      ]
      switch (shipmentId % 6) {
        case 0:
          // Completed
          responseObj.eta = '2021-10-12T15:29:47.113032Z'
          responseObj.status = {
            description: 'Delivered',
            type: ShipmentStatus.Delivered
          }
          responseObj.steps = [
            {
              stopNumber: 1,
              stopType: 'ORIGIN',
              location: {
                address: {
                  name: "World's Finest Chocolate",
                  lines: ['4801 S Lawndale Road'],
                  postalCode: '60632',
                  city: 'Chicago',
                  province: 'IL',
                  countryCode: 'US'
                },
                timezoneIdentifier: 'America/Chicago'
              }
            },
            {
              stopNumber: 2,
              stopType: 'DESTINATION',
              location: {
                address: {
                  name: "World's Finest Chocolate",
                  lines: ['C/O MCCS LOUISANA', '10057 E Airline Hwy'],
                  postalCode: '70087',
                  city: 'St. Rose',
                  province: 'LA',
                  countryCode: 'US'
                },
                coordinates: null,
                timezoneIdentifier: 'America/Chicago'
              }
            }
          ]
          break
        case 1:
          // In transit
          responseObj.eta = '2021-10-12T14:39:47.113032Z'
          responseObj.status = {
            description: 'En Route',
            type: ShipmentStatus.EnRoute
          }
          responseObj.steps = [
            {
              stopNumber: 1,
              stopType: 'ORIGIN',
              location: {
                address: {
                  name: "World's Finest Chocolate",
                  lines: ['4801 S Lawndale Road'],
                  postalCode: '60632',
                  city: 'Chicago',
                  province: 'IL',
                  countryCode: 'US'
                },
                coordinates: null,
                timezoneIdentifier: 'America/Chicago'
              }
            },
            {
              stopNumber: 2,
              stopType: 'LATEST',
              location: null
            },
            {
              stopNumber: 3,
              stopType: 'DESTINATION',
              location: {
                address: {
                  name: "World's Finest Chocolate",
                  lines: ['C/O MCCS LOUISANA', '10057 E Airline Hwy'],
                  postalCode: '70087',
                  city: 'St. Rose',
                  province: 'LA',
                  countryCode: 'US'
                },
                coordinates: null,
                timezoneIdentifier: 'America/Chicago'
              }
            }
          ]
          break
        case 2:
          // Not yet departed
          responseObj.eta = '2021-10-12T15:29:47.113032Z'
          responseObj.status = {
            description: 'Not yet shipped',
            type: ShipmentStatus.NotStarted
          }
          responseObj.steps = [
            {
              stopNumber: 1,
              stopType: 'ORIGIN',
              location: {
                address: {
                  name: "World's Finest Chocolate",
                  lines: ['4801 S Lawndale Road'],
                  postalCode: '60632',
                  city: 'Chicago',
                  province: 'IL',
                  countryCode: 'US'
                },
                coordinates: null,
                timezoneIdentifier: 'America/Chicago'
              },
              stopStatus: {
                statusCode: 'WAITING_TO_BE_SHIPPED',
                arrivalCode: null,
                arrivalTime: null,
                departureTime: null,
                eta: null
              }
            },
            {
              stopNumber: 2,
              stopType: 'DESTINATION',
              location: {
                address: {
                  name: "World's Finest Chocolate",
                  lines: ['C/O MCCS LOUISANA', '10057 E Airline Hwy'],
                  postalCode: '70087',
                  city: 'St. Rose',
                  province: 'LA',
                  countryCode: 'US'
                },
                coordinates: null,
                timezoneIdentifier: 'America/Chicago'
              },
              stopStatus: {
                statusCode: null,
                arrivalCode: null,
                arrivalTime: null,
                departureTime: null,
                eta: null
              }
            }
          ]
          break
        case 3:
          // Unknown 1 - has no info whatsoever
          responseObj.carriers = []
          break
        case 4:
          // Unknown 2 - has start and destination, but no current
          responseObj.steps = [
            {
              stopNumber: 1,
              stopType: 'ORIGIN',
              location: {
                address: {
                  name: "World's Finest Chocolate",
                  lines: ['4801 S Lawndale Road'],
                  postalCode: '60632',
                  city: 'Chicago',
                  province: 'IL',
                  countryCode: 'US'
                },
                coordinates: null,
                timezoneIdentifier: 'America/Chicago'
              }
            },
            {
              stopNumber: 2,
              stopType: 'DESTINATION',
              location: {
                address: {
                  name: "World's Finest Chocolate",
                  lines: ['C/O MCCS LOUISANA', '10057 E Airline Hwy'],
                  postalCode: '70087',
                  city: 'St. Rose',
                  province: 'LA',
                  countryCode: 'US'
                },
                coordinates: null,
                timezoneIdentifier: 'America/Chicago'
              }
            }
          ]
          break
        case 5:
        default:
          // placeholder for error
          responseObj.eta = '2021-10-12T15:29:47.113032Z'
          responseObj.status = {
            description: 'Called back to origin terminal',
            type: ShipmentStatus.Error
          }
          responseObj.steps = [
            {
              stopNumber: 1,
              stopType: 'ORIGIN',
              location: {
                address: {
                  name: "World's Finest Chocolate",
                  lines: ['4801 S Lawndale Road'],
                  postalCode: '60632',
                  city: 'Chicago',
                  province: 'IL',
                  countryCode: 'US'
                },
                coordinates: null,
                timezoneIdentifier: 'America/Chicago'
              }
            },
            {
              stopNumber: 2,
              stopType: 'DESTINATION',
              location: {
                address: {
                  name: "World's Finest Chocolate",
                  lines: ['C/O MCCS LOUISANA', '10057 E Airline Hwy'],
                  postalCode: '70087',
                  city: 'St. Rose',
                  province: 'LA',
                  countryCode: 'US'
                },
                coordinates: null,
                timezoneIdentifier: 'America/Chicago'
              }
            }
          ]
          break
      }
      return res(ctx.status(200), ctx.delay(100), ctx.json(responseObj))
    }
  ),
  // Handles a GET /trackingUpdates request
  rest.get(
    baseUrl +
      '/api/portal/customer/:tenantId/shipment/:shipmentId/trackingUpdates',
    (req, res, ctx) => {
      const shipmentId = req.params.shipmentId
      const trackingNumber = req.url.searchParams.get('trackingNumber')
      if (shipmentId === '1') {
        return res(
          ctx.status(400),
          ctx.delay(1000),
          ctx.json({
            message: '(Mock) internal server error (400)'
          })
        )
      }
      // Denote parcel with p in shipment Id for MSW
      if (shipmentId.includes('p') && !trackingNumber) {
        return res(
          ctx.status(400),
          ctx.delay(100),
          ctx.json({
            message: 'Tracking update number is required for parcel shipments'
          })
        )
      }
      if (!shipmentId.includes('p') && trackingNumber) {
        return res(
          ctx.status(400),
          ctx.delay(100),
          ctx.json({
            message:
              'Tracking update number should not be provided for non-parcel shipments'
          })
        )
      }
      if (shipmentId === '105') {
        const responseObj = []
        return res(ctx.status(200), ctx.delay(100), ctx.json(responseObj))
      }
      return res(
        ctx.status(200),
        ctx.delay(1000),
        ctx.json([
          {
            stopNumber: 2,
            sourceTimestamp: '2022-07-08T16:33:00Z',
            summaryCode: 'COMPLETED',
            summaryCodeDescription: 'Completed Unloading at Delivery Location',
            detailCode: null,
            detailCodeDescription: null,
            messages: [],
            milestone: 'STOP',
            location: {
              address: {
                name: "World's Finest Chocolate",
                lines: ['C/O MCCS Louisiana', '10057 E Airline Hwy'],
                postalCode: '70087',
                city: 'St. Rose',
                province: 'LA',
                countryCode: 'USA'
              },
              coordinates: {
                latitude: 41.5235,
                longitude: -90.5767
              },
              timezoneIdentifier: 'America/Chicago'
            }
          },
          {
            stopNumber: 1,
            sourceTimestamp: '2022-07-11T15:33:00Z',
            summaryCode: 'INFO',
            summaryCodeDescription: 'Exception',
            detailCode: 'EXCEPTION',
            detailCodeDescription: 'Shipment Delayed',
            messages: [
              {
                type: 'EXCEPTION',
                summaryCode: 'SD',
                summaryCodeDescription: 'Shipment Delayed',
                detailCode: 'NS',
                detailCodeDescription: 'Normal Status',
                message: null
              }
            ],
            milestone: 'ERROR',
            location: {
              address: {
                name: 'DC Troy',
                lines: ['S Stanfield Rd'],
                postalCode: '45373',
                city: 'Troy',
                province: 'OH',
                countryCode: 'USA'
              },
              coordinates: {
                latitude: 40.0384,
                longitude: -84.2041
              },
              timezoneIdentifier: 'America/New_York'
            }
          },
          {
            stopNumber: 1,
            sourceTimestamp: '2020-09-15T16:54:00Z',
            summaryCode: 'INFO',
            summaryCodeDescription: 'Completed Loading at Pick-up Location',
            detailCode: null,
            detailCodeDescription: null,
            messages: [],
            milestone: null,
            location: {
              address: {
                name: 'DC Troy',
                lines: ['S Stanfield Rd'],
                postalCode: '45373',
                city: 'Troy',
                province: 'OH',
                countryCode: 'USA'
              },
              coordinates: {
                latitude: 40.0384,
                longitude: -84.2041
              },
              timezoneIdentifier: 'America/New_York'
            }
          },
          {
            stopNumber: 1,
            sourceTimestamp: '2020-09-14T16:41:00Z',
            summaryCode: 'AT_STOP',
            summaryCodeDescription: 'Arrived at Stop',
            detailCode: null,
            detailCodeDescription: null,
            messages: [],
            milestone: null,
            location: {
              address: {
                name: "World's Finest Chocolate",
                lines: ['4801 S Lawndale Road'],
                postalCode: '60632',
                city: 'Chicago',
                province: 'IL',
                countryCode: 'USA'
              },
              coordinates: {
                latitude: 40.0384,
                longitude: -84.2041
              },
              timezoneIdentifier: 'America/Chicago'
            }
          },
          {
            stopNumber: 1,
            sourceTimestamp: '2020-09-14T16:41:00Z',
            summaryCode: 'AT_STOP',
            summaryCodeDescription: 'Arrived at Stop',
            detailCode: null,
            detailCodeDescription: null,
            messages: [],
            milestone: 'STOP',
            location: {
              address: {
                name: "World's Finest Chocolate",
                lines: ['4801 S Lawndale Road'],
                postalCode: '60632',
                city: 'Chicago',
                province: 'IL',
                countryCode: 'USA'
              },
              coordinates: {
                latitude: 40.0384,
                longitude: -84.2041
              },
              timezoneIdentifier: 'America/Chicago'
            }
          },
          {
            stopNumber: 1,
            sourceTimestamp: '2020-09-14T16:41:00Z',
            summaryCode: 'AT_STOP',
            summaryCodeDescription: 'Arrived at Stop',
            detailCode: null,
            detailCodeDescription: null,
            messages: [],
            milestone: 'STOP',
            location: {
              address: {
                name: "World's Finest Chocolate",
                lines: ['4801 S Lawndale Road'],
                postalCode: '60632',
                city: 'Chicago',
                province: 'IL',
                countryCode: 'USA'
              },
              coordinates: {
                latitude: 40.0384,
                longitude: -84.2041
              },
              timezoneIdentifier: 'America/Chicago'
            }
          }
        ])
      )
    }
  )
]
