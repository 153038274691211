import React from 'react'
// # region Redux
import { useDispatch, useSelector } from 'react-redux'
// # endregion
// # region Components
import ComponentLoader from '../../components/ComponentLoader'
import TrackingUpdatesEventsSection from './TrackingUpdatesEventsSection'
// # endregion

/**
 * A component which handles showing loading/content. The content is
 * the timeline which shows times and details of milestones and other
 * less important updates
 *
 * @returns The delivery tracking events section component
 */
export default function TrackingUpdatesEventsSectionLoader () {
  const {
    deliveryTrackingUpdatesLoadingStatus,
    error,
    itemsToDisplay
  } = useSelector(
    (state) => state.deliveryTrackingUpdates
  )

  const dispatch = useDispatch()

  return (
    <ComponentLoader
      loadingSpinnerTopMargin='0px'
      loadingStatus={deliveryTrackingUpdatesLoadingStatus}
      loadingText='Loading...'
      successComponent={
        <TrackingUpdatesEventsSection
          dispatch={dispatch}
          itemsToDisplay={itemsToDisplay}
        />
      }
      consoleErrorMessage={error?.errorContent?.data?.message}
      errorStatus={error?.errorContent?.status}
      failedLoadText='Something went wrong. Try again later.'
    />
  )
}
