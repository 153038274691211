import { DateTime } from 'luxon';

/**
 * Gets the date of the week in a 3-char abbreviation
 * @deprecated - We no longer use JavaScript Date. Instead we have switched to using luxon's DateTime format
 * for all Dates and Times. Please conver the Date to DateTime format and avoid usage of this.
 *
 * @param dateObj A date object
 * @returns The date of the week
 */
export function get3DowString(dateObj) {
  if (!isValidDateObject(dateObj)) {
    return '';
  }
  return getDowString(dateObj).substring(0, 3);
}

/**
 * Gets the date of the week string
 * @deprecated - We no longer use JavaScript Date. Instead we have switched to using luxon's DateTime format
 * for all Dates and Times. Please conver the Date to DateTime format and avoid usage of this.
 * @param dateObj A date object
 * @returns The date of the week
 */
export function getDowString(dateObj) {
  if (!isValidDateObject(dateObj)) {
    return '';
  }
  const weekdays = new Array(7);
  weekdays[0] = 'Sunday';
  weekdays[1] = 'Monday';
  weekdays[2] = 'Tuesday';
  weekdays[3] = 'Wednesday';
  weekdays[4] = 'Thursday';
  weekdays[5] = 'Friday';
  weekdays[6] = 'Saturday';
  return weekdays[dateObj.getDay()];
}

/**
 * Gets a month string from a date object
 *
 * @param dateObj The date object to get the month from
 * @returns The month
 */
export function getMonthString(dateObj) {
  if (!isValidDateObject(dateObj)) {
    return '';
  }
  const months = new Array(12);
  months[0] = 'January';
  months[1] = 'February';
  months[2] = 'March';
  months[3] = 'April';
  months[4] = 'May';
  months[5] = 'June';
  months[6] = 'July';
  months[7] = 'August';
  months[8] = 'September';
  months[9] = 'October';
  months[10] = 'November';
  months[11] = 'December';
  return months[dateObj.getMonth()];
}

/**
 * Gets a time string in 24hr format
 * @deprecated - We no longer use JavaScript Date. Instead we have switched to using luxon's DateTime format
 * for all Dates and Times. Please conver the Date to DateTime format and avoid usage of this.
 * @param dateObj The date object to get the specified time string from
 * @returns A time string in 24 hour format
 */
export function get24HrTime(dateObj) {
  if (!isValidDateObject(dateObj)) {
    return '';
  }
  const hour = ('00' + dateObj.getHours()).slice(-2);
  const minute = ('00' + dateObj.getMinutes()).slice(-2);
  return `${hour}:${minute}`;
}

/**
 * Gets a Date objected converted to a specific time zone
 * @deprecated - We no longer use JavaScript Date. Instead we have switched to using luxon's DateTime format
 * for all Dates and Times. Please conver the Date to DateTime format and avoid usage of this.
 * Use getConvertedDateTimeObj instead.
 *
 * @param dateObj The date object to get the specified time string from
 * @param desiredTimeZone The time zone to convert to
 * @returns A converted Date object
 */
export function getConvertedDateObj(dateObj, desiredTimeZone) {
  if (!isValidDateObject(dateObj)) {
    return '';
  }
  return new Date(dateObj.toLocaleString('en-US', desiredTimeZone && { timeZone: desiredTimeZone }));
}

/**
 * Gets a string and converts to a DateTime objected converted to a specific time zone
 *
 * @param dateStr The specified date time as a string
 * @param desiredTimeZone The time zone to convert to as a stirng
 * @returns A converted DateTime object
 */
export function getConvertedDateTimeObj(dateStr, desiredTimeZone) {
  if (!dateStr) {
    return '';
  }

  return DateTime.fromISO(dateStr, {
    locale: 'en-US',
  }).setZone(desiredTimeZone);
}

/**
 * Gets a time string in 24hr format
 *
 * @deprecated We no longer use JavaScript Date, and have switched to luxon DateTime
 * Please convert the Date object into DateTime and use luxon functions instead
 *
 * @param dateObj The date object to get the specified time string from
 * @returns A time string in 24hr format: HH:mm TZ
 */
export function get24Str(dateObj) {
  if (!isValidDateObject(dateObj)) {
    return '';
  }
  return `${get24HrTime(dateObj)} ${getTimeZone(dateObj)}`;
}

/**
 * Gets a date string in M/D/YY format
 * @deprecated We no longer use JavaScript Date, and have switched to luxon DateTime
 * Please convert the Date object into DateTime and use luxon's function ".getLocaleString()" instead
 *
 * @param dateObj The date object to get the specified date string from
 * @returns A date string in M/D/YY format
 */
export function getDateStr(dateObj) {
  if (!isValidDateObject(dateObj)) {
    return '';
  }
  return `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${`${dateObj.getFullYear()}`.substr(2)}`;
}

/**
 * Gets a d.o.w. string
 *  @deprecated We no longer use JavaScript Date, and have switched to luxon DateTime.
 *  Please convert the Date object into DateTime instead.
 *
 * @param dateObj The date object to get the specified date string from
 * @returns A d.o.w. string
 */
export function getDowStr(dateObj) {
  if (!isValidDateObject(dateObj)) {
    return '';
  }
  return get3DowString(dateObj);
}

/**
 * Gets a date and time string in 24hr format
 *
 *  @deprecated We no longer use JavaScript Date, and have switched to luxon DateTime.
 *  Please convert the Date object into DateTime instead.
 *
 * @param dateObj The date object to get the specified time string from
 * @param desiredTimeZone The timezone to convert to
 * @returns A date-time string in 24hr format: DOW3 M/D/YY HH:mm TZ
 */
export function getConvertedDate24Str(dateObj, desiredTimeZone) {
  if (!isValidDateObject(dateObj)) {
    return '';
  }
  const convertedDateObj = getConvertedDateObj(dateObj, desiredTimeZone);
  const dowPart = get3DowString(convertedDateObj);
  const datePart = `${
    convertedDateObj.getMonth() + 1
  }/${convertedDateObj.getDate()}/${`${convertedDateObj.getFullYear()}`.substr(2)}`;
  const timePart = `${get24HrTime(dateObj)} ${getTimeZone(dateObj)}`;
  return `${dowPart} ${datePart} ${timePart}`;
}

/**
 * Gets a time zone
 *  @deprecated We no longer use JavaScript Date, and have switched to luxon DateTime.
 *  Please convert the Date object into DateTime instead.
 *
 * @param dateObj The date object to get the time zone from
 * @returns A time zone
 */
export function getTimeZone(dateObj) {
  if (!isValidDateObject(dateObj)) {
    return '';
  }
  const dateStr = dateObj.toLocaleString(undefined, { timeZoneName: 'short' });
  const dateArr = dateStr.split(' ');
  return dateArr[dateArr.length - 1];
}

/**
 * Checks if passed date object is a valid date object
 *
 * @deprecated We no longer use JavaScript Date, and have switched to luxon DateTime.
 *  Please convert the Date object into DateTime instead.
 *
 * @param dateObj The date object
 * @returns true if it is a valid date object, false otherwise
 */
function isValidDateObject(dateObj) {
  if (!dateObj) {
    return false;
  }
  return Object.prototype.toString.call(dateObj) === '[object Date]' && !isNaN(dateObj.getTime());
}

/**
 * Gets a sentence case string from a string. Also replaces underscores with spaces.
 * Does not convert the orinal string. This is made for codes such as "EN_ROUTE"
 *
 * @param str The string to get the sentence case version of
 * @returns A sentence case version of the string
 */
export function getSentenceCase(str) {
  if (!str || str.length < 1) {
    return '';
  }
  return `${str.substring(0, 1).toUpperCase()}${str.substring(1).replace(/_/g, ' ').toLowerCase()}`;
}
