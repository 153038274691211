import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import configureStore from './redux/store'

// Store
const store = configureStore({})
const state = store.getState()

// MSW
if (state.env.NODE_ENV === 'development' && state.env.REACT_APP_RW_USE_MOCK === 'true') {
  const { worker } = require('./mocks/browser')
  worker.start()
}

let tenantShortName = window.location.host.split('.')[0]

if (state.env.NODE_ENV === 'development' && state.env.REACT_APP_RW_TENANT_SHORT_NAME) {
  tenantShortName = state.env.REACT_APP_RW_TENANT_SHORT_NAME
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App tenantShortName={tenantShortName} />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
