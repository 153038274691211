import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './root-reducer'
import rootSaga from './root-saga'

export default function configureStore (preloadedState) {
  // create our saga middleware
  const sagaMiddleware = createSagaMiddleware()

  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose

  const middlewares = []

  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(sagaMiddleware, ...middlewares))
  )

  // run the sagas
  for (let i = 0; i < rootSaga.length; ++i) {
    sagaMiddleware.run(rootSaga[i])
  }

  return store
}
