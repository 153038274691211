export const FETCH_DELIVERY_TRACKING_UPDATES =
  'FETCH_DELIVERY_TRACKING_UPDATES'
export const UPDATE_DELIVERY_TRACKING_UPDATES =
  'UPDATE_DELIVERY_TRACKING_UPDATES'
export const EXPAND_DELIVERY_TRACKING_UPDATES =
  'EXPAND_DELIVERY_TRACKING_UPDATES'

export const fetchDeliveryTrackingUpdates = (tenantId, shipmentId, trackingNumber) => ({
  type: FETCH_DELIVERY_TRACKING_UPDATES,
  payload: { tenantId, shipmentId, trackingNumber }
})

export const updateDeliveryTrackingUpdates = (deliveryTrackingUpdates) => ({
  type: UPDATE_DELIVERY_TRACKING_UPDATES,
  payload: {
    ...deliveryTrackingUpdates
  }
})

export const expandDeliveryTrackingUpdates = (childNodes) => ({
  type: EXPAND_DELIVERY_TRACKING_UPDATES,
  payload: { childNodes }
})
