import Axios from 'axios'
import { call, put, takeLeading } from 'redux-saga/effects'

// # region Actions
import { FETCH_COMPANY_BRANDING, updateSiteBranding } from './branding-actions'
// # endregion

const apiGetRequest = Axios.create({
  method: 'GET',
  headers: [],
  timeout: 10000,
  baseURL: process.env.REACT_APP_RW_API_BASE_URL,
  validateStatus: function (status) {
    return status === 204 || status < 300
  }
})

const fetchBrandingFromtenantShortName = async (tenantShortName) => {
  try {
    const result = await apiGetRequest.request(
      `/api/portal/customer/${tenantShortName}/branding`
    )
    return result
  } catch (err) {
    return { hasError: true, errorContent: err.response }
  }
}

export function * fetchBranding (action) {
  const data = yield call(
    fetchBrandingFromtenantShortName,
    action.payload.tenantShortName
  )

  yield put(
    updateSiteBranding({
      ...data
    })
  )
}

export default function * brandingSaga () {
  yield takeLeading(FETCH_COMPANY_BRANDING, fetchBranding)
}
