import React from 'react'
import PropTypes from 'prop-types'
import { AddressPropTypeShape } from '../constants'

/**
 * Creates a formatted address block
 *
 * @param fontStyle Whether to style the address with bold, italic, or neither
 * @returns An address in a formatted style
 */
const AddressText = function (props) {
  if (!props.address) {
    return ''
  }
  return (
    <div data-testid='address-text-root' className='address-text-root'>
      <div data-testid='address-text-name' className={`address-text-${props.fontStyle}`}>
        {props.address.name}
      </div>
      {props.address.lines && props.address.lines.map((line, idx) => (
        <div key={idx} data-testid={`address-text-line-${idx}`} className={`address-text-${props.fontStyle}`}>
          {line}
        </div>
      ))}
      {(props.address.city || props.address.province || props.address.postalCode) &&
        <div data-testid='address-text-city-state-zip' className={`address-text-${props.fontStyle}`}>
          {props.address.city}, {props.address.province} {props.address.postalCode}
        </div>}
    </div>
  )
}

AddressText.defaultProps = {
  fontStyle: 'regular'
}
AddressText.propTypes = {
  fontStyle: PropTypes.oneOf(['regular', 'bold', 'italic']),
  address: AddressPropTypeShape
}

export default AddressText
