import React from 'react'
import PropTypes from 'prop-types'
// # region Components
import LabeledData from '../../components/LabeledData'
import { EmptyUpdates } from '../../assets/icons/EmptyUpdates'
import { Col, Divider, Empty, Row } from 'antd'
// # endregion
import { CarrierPropType } from '../../constants'

/**
 * The carrier info section. Typically shows name, SCAC, etc.
 *
 * @param props.carriers The shipment carrier lanes information
 * @returns The carrier info section
 */
const CarrierInformationSection = function (props) {
  if (!props.carriers || props.carriers.length === 0) {
    return (
      <Empty
        data-testid='carrier-info-section-root-empty'
        image={
          <EmptyUpdates className='tracking-updates-events-section-empty-image' />
      }
        description='No Carrier Information is Available'
      />
    )
  }
  return (
    <div data-testid='carrier-info-section-root-nonempty' className='carrier-info-section-root'>
          <Row gutter={[16]} align='top'>
      {props.shipmentNumber &&
        <Col className='carrier-info-section-field' xs={24} sm={12} md={8} lg={7} xl={6} xxl={6}>
          <LabeledData label='Shipment #' value={props.shipmentNumber} />
        </Col>}
        {props.trackingNumber &&
        <Col className='carrier-info-section-field' xs={24} sm={12} md={8} lg={7} xl={6} xxl={6}>
          <LabeledData label='Tracking #' value={props.trackingNumber} />
          </Col>}
        </Row>
      {props.carriers && Array.isArray(props.carriers) && props.carriers.map((carrierInfo, carrierIdx) => (
        <div key={carrierIdx}>
          {carrierIdx !== 0 && <Divider />}
          <Row gutter={[16]} align='top'>
            <Col
              className='carrier-info-section-field'
              xs={24} sm={12} md={8} lg={7} xl={6} xxl={6}
            >
              <LabeledData label='Name' value={carrierInfo?.name} />
            </Col>
            {carrierInfo.identifiers && Array.isArray(carrierInfo.identifiers) && carrierInfo.identifiers.reduce(function (filteredIdentifiers, identifier) {
              if (identifier.value) {
                filteredIdentifiers.push(identifier)
              }
              return filteredIdentifiers
            }, []).map((identifier, identifierIdx) => (
              <Col
                key={identifierIdx}
                className='carrier-info-section-field'
                xs={24} sm={12} md={8} lg={7} xl={6} xxl={6}
              >
                <LabeledData label={identifier.type} value={identifier.value} />
              </Col>
            ))}
          </Row>
          {carrierInfo.contacts && Array.isArray(carrierInfo.contacts) && carrierInfo.contacts.map((contactInfo, contactIdx) => (
            <Row gutter={[16]} key={contactIdx} align='top'>
              <Col
                className='carrier-info-section-field'
                xs={24} sm={12} md={8} lg={7} xl={6} xxl={6}
              >
                <LabeledData label={contactInfo.companyName} value={getAddressLines(contactInfo.address)} />
              </Col>
              {contactInfo.phoneNumbers &&
                <Col className='carrier-info-section-field' xs={24} sm={12} md={8} lg={7} xl={6} xxl={6}>
                  <LabeledData label='Phone' value={contactInfo.phoneNumbers} />
                </Col>}
              {contactInfo.emailAddress &&
                <Col className='carrier-info-section-field' xs={24} sm={12} md={8} lg={7} xl={6} xxl={6}>
                  <LabeledData label='Email' value={contactInfo.emailAddress} />
                </Col>}
            </Row>))}
        </div>
      ))}
    </div>
  )
}

function getAddressLines (address) {
  let lines = []
  if (address.name) {
    lines = lines.concat(address.name)
  }
  let line2 = []
  for (const line of address.lines) {
    if (line) {
      line2 = line2.concat(line)
    }
  }
  if (address.city) {
    line2 = line2.concat(address.city)
  }
  if (address.province) {
    line2 = line2.concat(address.province)
  }
  const line2Str = line2.join(', ')
  if (line2Str) {
    lines = lines.concat(line2Str)
  }
  return lines
}

CarrierInformationSection.propTypes = {
  carriers: PropTypes.arrayOf(CarrierPropType)
}

export default CarrierInformationSection
