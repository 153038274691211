import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
// # region Redux
import { useDispatch, useSelector } from 'react-redux'
import { fetchCompanyBranding } from '../../redux/branding/branding-actions'
// # endregion
// # region Components
import ComponentLoader from '../../components/ComponentLoader'
import DeliverySummaryPage from './DeliverySummaryPage'
// # endregion

/**
 * A component which handles showing loading/content. The content includes
 * everything in the tracking page
 *
 * @returns The delivery summary page component
 */
export default function DeliverySummaryPageLoader ({ tenantShortName }) {
  const routingParams = useParams()
  const { shipmentId, trackingNumber } = routingParams

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCompanyBranding(tenantShortName))
  }, [tenantShortName, dispatch])

  const { brandingLoadingStatus, error, data } = useSelector((state) => state.branding)

  return (
    <ComponentLoader
      loadingSpinnerTopMargin='60px'
      loadingStatus={brandingLoadingStatus}
      loadingText='Loading...'
      successComponent={
        <DeliverySummaryPage
          logoUrl={data?.logoUrl}
          name={data?.name}
          colors={data?.colors}
          tenantId={data?.tenantId}
          shipmentId={shipmentId}
          trackingNumber={trackingNumber}
        />
      }
      consoleErrorMessage={error?.errorContent?.data?.message}
      errorStatus={error?.errorContent?.status}
      failedLoadText='Something went wrong. Try again later.'
    />
  )
}
