import React from 'react'
import PropTypes from 'prop-types'
// # region Components
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import DeliverySummaryLoader from './DeliverySummaryLoader'
// # endregion

/**
 * A component which handles showing loading/content. The content includes
 * everything in the tracking page
 *
 * @returns The delivery summary page component
 */
const DeliverySummaryPage = function (props) {
  return (
    <div className='delivery-summary-page-root'>
      <div className='delivery-summary-page-header-and-content'>
        <Header brandingImage={props.logoUrl} brandingName={props.name} brandingColor={props.colors?.primary} />
        <DeliverySummaryLoader tenantId={props.tenantId} shipmentId={props.shipmentId} trackingNumber={props.trackingNumber} />
      </div>
      <Footer brandingColor={props.colors?.secondary} />
    </div>
  )
}

DeliverySummaryPage.propTypes = {
  logoUrl: PropTypes.string,
  name: PropTypes.string,
  colors: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string
  }),
  tenantId: PropTypes.string,
  shipmentId: PropTypes.string,
  trackingNumber: PropTypes.string
}

export default DeliverySummaryPage
