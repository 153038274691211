import React from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import './App.css'
import DeliverySummaryPageLoader from './Delivery/components/DeliverySummaryPageLoader'

/**
 * The tracking app. This control also handles routing, given the passed parameters
 *
 * @returns The overall app component
 */
export default function App ({ tenantShortName }) {
  return (
    <Router>
      <div>
        <Switch>
          <Route
            path='/shipment/:shipmentId/:trackingNumber?'
            component={() => <DeliverySummaryPageLoader tenantShortName={tenantShortName} />}
          />
        </Switch>
      </div>
    </Router>
  )
}
