export const FETCH_DELIVERY_DETAILS = 'FETCH_DELIVERY_DETAILS'
export const UPDATE_DELIVERY_DETAILS = 'UPDATE_DELIVERY_DETAILS'

export const fetchDeliveryDetails = (tenantId, shipmentId, trackingNumber) => ({
  type: FETCH_DELIVERY_DETAILS,
  payload: { tenantId: tenantId, shipmentId: shipmentId, trackingNumber: trackingNumber }
})

export const updateDeliveryDetails = (deliveryDetails) => ({
  type: UPDATE_DELIVERY_DETAILS,
  payload: {
    ...deliveryDetails
  }
})
