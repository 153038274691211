import PropTypes from 'prop-types';

export const ShipmentStatus = {
  Delivered: 'DELIVERED',
  EnRoute: 'EN_ROUTE',
  Error: 'ERROR',
  NotStarted: 'NOT_STARTED',
  Unknown: 'UNKNOWN',
};

export const TrackingUpdatesTimelineType = {
  ShowHide: 'showHide',
  NonMilestone: 'nonMilestone',
  Milestone: 'milestone',
};

export const MilestoneType = {
  StatusTransition: 'STATUS_TRANSITION',
  Stop: 'STOP',
  Error: 'ERROR',
};

export const ColorHexValue = {
  Red: '#cf1322',
  Gray: '#8c8c8c',
};

export const StopType = {
  Origin: 'ORIGIN',
  Latest: 'LATEST',
  Destination: 'DESTINATION',
};

export const AddressPropTypeShape = PropTypes.shape({
  name: PropTypes.string,
  lines: PropTypes.arrayOf(PropTypes.string),
  postalCode: PropTypes.string,
  city: PropTypes.string,
  province: PropTypes.string,
  countryCode: PropTypes.string,
});

export const LocationPropType = PropTypes.shape({
  address: AddressPropTypeShape,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  timezoneIdentifier: PropTypes.string,
});

export const ShipmentStatusPropType = PropTypes.shape({
  description: PropTypes.string,
  type: PropTypes.string,
});

export const ContactPropType = PropTypes.shape({
  companyName: PropTypes.string,
  address: AddressPropTypeShape,
  phoneNumbers: PropTypes.arrayOf(PropTypes.string),
  emailAddress: PropTypes.string,
});

export const CarrierPropType = PropTypes.shape({
  name: PropTypes.string,
  identifiers: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  contacts: PropTypes.arrayOf(ContactPropType),
});

export const StepPropType = PropTypes.shape({
  stopNumber: PropTypes.number,
  stopType: PropTypes.oneOf(['ORIGIN', 'LATEST', 'DESTINATION']),
  location: LocationPropType,
});
