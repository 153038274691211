import React from 'react'
import PropTypes from 'prop-types'
import { getSentenceCase } from '../../common-functions'
import { LocationPropType, MilestoneType, TrackingUpdatesTimelineType } from '../../constants'
// # region Redux
import { expandDeliveryTrackingUpdates } from '../redux/deliveryTrackingUpdates-actions'
// # endregion
// # region Components
import { SmallCircleIcon } from '../../assets/icons/SmallCircle'
import { EmptyUpdates } from '../../assets/icons/EmptyUpdates'
import AddressText from '../../components/AddressText'
import { Button, Empty, Timeline, Grid } from 'antd'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
// # endregion
const { useBreakpoint } = Grid

/**
 * A component which handles showing loading/content. The content is
 * the timeline which shows times and details of milestones and other
 * less important updates
 *
 * @returns The delivery tracking events section component
 */
const TrackingUpdatesEventsSection = function (props) {
  const screens = useBreakpoint()
  const xs = screens.xs

  if (props.itemsToDisplay && props.itemsToDisplay.length > 0) {
    return (
      <div data-testid='tracking-updates-events-section-nonempty' className='tracking-updates-events-section-root'>
        <Timeline className='tracking-updates-events-section-timeline' mode={!xs && 'left'}>
          {props.itemsToDisplay.map((eventOrGroup) => (
            <Timeline.Item
              key={eventOrGroup.index}
              className={eventOrGroup.isMostRecentEvent ? 'tracking-updates-events-section-first' : 'tracking-updates-events-section-rest'}
              label={
                !(xs || eventOrGroup.type === TrackingUpdatesTimelineType.ShowHide) &&
                eventOrGroup.dateTimeString
              }
              dot={
                eventOrGroup.type === TrackingUpdatesTimelineType.ShowHide
                  ? (
                      eventOrGroup.expanded
                        ? (
                          <DownOutlined aria-hidden />
                          )
                        : (
                          <RightOutlined aria-hidden />
                          )
                    )
                  : (
                      eventOrGroup.type === TrackingUpdatesTimelineType.NonMilestone &&
                        <SmallCircleIcon />
                    )
              }
              color={eventOrGroup.color}
            >
              {eventOrGroup.type === TrackingUpdatesTimelineType.ShowHide
                ? (
                  <Button
                    type='link'
                    onClick={() =>
                      props.dispatch(
                        expandDeliveryTrackingUpdates(eventOrGroup.value)
                      )}
                  >
                    {eventOrGroup.expanded
                      ? 'Hide detailed updates'
                      : 'Show detailed updates'}
                  </Button>
                  )
                : (
                  <>
                    {xs && <p>{eventOrGroup.dateTimeString}</p>}
                    <p className='tracking-updates-events-section-timeline-item-label'>
                      {getItemLabelText(getSentenceCase(eventOrGroup.title), getSentenceCase(eventOrGroup.titleDescription))}
                    </p>
                    {eventOrGroup.value?.location?.address && <AddressText address={eventOrGroup.value?.location?.address} fontStyle='italic' />}
                  </>
                  )}
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    )
  } else {
    return (
      <Empty
        data-testid='tracking-updates-events-section-empty'
        image={
          <EmptyUpdates className='tracking-updates-events-section-empty-image' />
        }
        description='No Tracking Updates are Available'
      />
    )
  }
}

function getItemLabelText (title, description) {
  const hasTitle = title && title !== ''
  const hasDescription = description && description !== ''
  if (hasTitle && hasDescription) {
    return `${title}: ${description}`
  } else if (hasTitle) {
    return title
  } else if (hasDescription) {
    return description
  } else {
    return ''
  }
}

TrackingUpdatesEventsSection.propTypes = {
  dispatch: PropTypes.any, // Dispatch<any>
  itemsToDisplay: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number,
    isMostRecentEvent: PropTypes.bool,
    dateTimeString: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(TrackingUpdatesTimelineType).map((key) => TrackingUpdatesTimelineType[key])),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        stopNumber: PropTypes.number,
        sourceTimestamp: PropTypes.string, // Date
        summaryCode: PropTypes.string,
        summaryCodeDescription: PropTypes.string,
        detailCode: PropTypes.string,
        detailCodeDescription: PropTypes.string,
        messages: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            summaryCode: PropTypes.string,
            summaryCodeDescription: PropTypes.string,
            detailCode: PropTypes.string,
            detailCodeDescription: PropTypes.string,
            message: PropTypes.string
          })
        ),
        milestone: PropTypes.oneOf(Object.keys(MilestoneType).map((key) => MilestoneType[key])),
        location: LocationPropType
      })
    ]),
    expanded: PropTypes.bool,
    color: PropTypes.string,
    title: PropTypes.string,
    titleDescription: PropTypes.string
  }))
}

export default TrackingUpdatesEventsSection
