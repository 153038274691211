import Axios from 'axios'
import { call, put, takeLeading } from 'redux-saga/effects'

// # region Actions
import {
  FETCH_DELIVERY_TRACKING_UPDATES,
  updateDeliveryTrackingUpdates
} from './deliveryTrackingUpdates-actions'
// # endregion

const apiGetRequest = Axios.create({
  method: 'GET',
  headers: [],
  timeout: 15000,
  baseURL: process.env.REACT_APP_RW_API_BASE_URL,
  validateStatus: function (status) {
    return status === 204 || status < 300 || status === 400
  }
})

const fetchDeliveryTrackingUpdatesFromId = async (
  tenantId,
  shipmentId,
  trackingNumber
) => {
  try {
    let url = `/api/portal/customer/${tenantId}/shipment/${shipmentId}/trackingUpdates`
    if (trackingNumber) {
      url += `/?trackingNumber=${trackingNumber}`
    }
    const result = await apiGetRequest.request(url)
    if (result?.status === 400) {
      return {
        hasError: true,
        errorContent: { status: 400, message: result.data }
      }
    }
    return result
  } catch (err) {
    return { hasError: true, errorContent: err.response }
  }
}

export function * fetchDeliveryTrackingUpdates (action) {
  const data = yield call(
    fetchDeliveryTrackingUpdatesFromId,
    action.payload.tenantId,
    action.payload.shipmentId,
    action.payload.trackingNumber
  )

  yield put(
    updateDeliveryTrackingUpdates({
      ...data
    })
  )
}

export default function * deliveryTrackingUpdatesSaga () {
  yield takeLeading(
    FETCH_DELIVERY_TRACKING_UPDATES,
    fetchDeliveryTrackingUpdates
  )
}
