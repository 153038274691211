// # region Actions
import {
  FETCH_DELIVERY_DETAILS,
  UPDATE_DELIVERY_DETAILS
} from './deliveryDetails-actions'
// # endregion
import { LoadingStatus } from '../../redux/constants'

const initialState = {
  deliveryDetailsLoadingStatus: LoadingStatus.idle,
  error: null,
  data: undefined
}

export default function deliveryDetailsReducer (state = initialState, action) {
  // eslint-disable-next-line
  switch (action.type) {
    case FETCH_DELIVERY_DETAILS: {
      return {
        ...state,
        deliveryDetailsLoadingStatus: LoadingStatus.loading
      }
    }
    case UPDATE_DELIVERY_DETAILS: {
      if (action.payload?.hasError) {
        return {
          ...state,
          error: { hasError: true, errorContent: action.payload?.errorContent },
          deliveryDetailsLoadingStatus: LoadingStatus.failed
        }
      }
      return {
        ...state,
        deliveryDetailsLoadingStatus: LoadingStatus.succeeded,
        ...action.payload
      }
    }
  }
  return state
}
