import React from 'react'
import PropTypes from 'prop-types'

/**
 * A label-value pair, with the value falling below the label
 *
 * @param big Whether this labeled data is part of a header, and should be bigger
 * @param icon The icon to show (if any) besides the labeled data (shows up besides the label)
 * @param label The label text
 * @param value The value text
 * @param valueColor The color of the value text
 * @returns A formatted label-value component
 */
const LabeledData = function (props) {
  let valueArr = []
  if (typeof props.value === 'string') {
    valueArr = valueArr.concat(props.value)
  } else {
    valueArr = props.value
  }
  return (
    <label data-testid='labeled-data-root' className='labeled-data-root'>
      <div
        data-testid='labeled-data-label'
        className={`labeled-data-label labeled-data-label-${
          props.big ? 'header' : 'regular'
        }`}
      >
        {(props.icon !== undefined && props.icon !== '') &&
          (<props.icon data-testid='labeled-data-icon' aria-hidden className='labeled-data-icon' />)}
        <span>{props.label}</span>
      </div>
      <div
        data-testid='labeled-data-value'
        className={`labeled-data-value labeled-data-value-${
          props.big ? 'header' : 'regular'
        } labeled-data-value-${props.valueColor}`}
      >
        {valueArr && Array.isArray(valueArr) && valueArr.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
    </label>
  )
}
LabeledData.defaultProps = {
  big: false,
  icon: '',
  value: '',
  valueColor: 'black'
}
LabeledData.propTypes = {
  big: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  valueColor: PropTypes.oneOf(['black', 'gray', 'red', 'green'])
}

export default LabeledData
