import { ErrorIcon } from '../../assets/icons/ErrorIcon';
import { ColorHexValue, MilestoneType, TrackingUpdatesTimelineType } from '../../constants';
import { getConvertedDateTimeObj } from '../../common-functions';
import { DateTime } from 'luxon';

/**
 * Gets the tracking updates information, as an object. This includes an array of items
 * to display on the UI, a list of all possible items to show, the list of items (which)
 * could be hidden) to show, and an icon to indicate whether there is an error-state
 * update in the history
 *
 * @param payload The tracking updates API response
 * @returns The information needed to display the tracking updates section
 */
export function getTrackingEventSectionData(payload) {
  // This tracks all events and buttons, and is used to update the display array as needed
  const allEventsAndButtons = [];
  let allEventsIndex = 0;
  // A hashset to track what's showing at any given moment
  const showingIndexSet = {};
  // Track the index of the last show/hide button. Used to set the from:to indexes of
  // allEventsAndButtons, so reset to -1 after setting the 'to' value
  let lastShowHide = -1;

  // The icon to show in the top right of the collapse panel
  let warnIcon = '';
  if (payload?.data) {
    // Identify the first event to style it differently
    let isFirst = true;
    for (const trackingEvent of payload.data) {
      const localTimeZone =
        trackingEvent &&
        trackingEvent.location &&
        trackingEvent.location.timezoneIdentifier &&
        trackingEvent.location.timezoneIdentifier;
      // If there is no timezone specified, just take the utc date and display it as is
      // eg 2022-08-26T07:50:00Z should be displayed as Friday 8/26/22 07:50
      const dateTime = localTimeZone
        ? getConvertedDateTimeObj(trackingEvent.sourceTimestamp, localTimeZone)
        : DateTime.fromISO(trackingEvent.sourceTimestamp).toUTC();
      const dateTimeStr = dateTime ? dateTime.toFormat('EEEE M/d/yy HH:mm') : null;

      // Non-milestone events
      if (trackingEvent.milestone === null) {
        // Start a new show/hide button if one hasn't already been
        if (lastShowHide === -1) {
          allEventsAndButtons.push({
            index: allEventsIndex,
            type: TrackingUpdatesTimelineType.ShowHide,
            dateTimeString: dateTimeStr,
            color: 'gray',
            value: `${allEventsIndex + 1}`,
            expanded: false,
          });
          showingIndexSet[`${allEventsIndex}`] = true;
          lastShowHide = allEventsIndex;
          allEventsIndex++;
        }
        // Add the event
        allEventsAndButtons.push({
          index: allEventsIndex,
          type: TrackingUpdatesTimelineType.NonMilestone,
          dateTimeString: dateTimeStr,
          color: 'gray',
          isMostRecentEvent: isFirst,
          value: trackingEvent,
          title: trackingEvent.summaryCode,
          titleDescription: trackingEvent.summaryCodeDescription,
        });
        isFirst = false;
        showingIndexSet[`${allEventsIndex}`] = false;
        allEventsIndex++;
      } else {
        // Milestone events
        // Get the warning icon, if required. If first event is an error event => red, else if any other event is an error event => gray, otherwise => none
        if (allEventsIndex === 0) {
          if (trackingEvent.milestone === MilestoneType.Error) {
            warnIcon = <ErrorIcon style={{ color: ColorHexValue.Red }} />;
          }
        }
        if (warnIcon === '' && trackingEvent.milestone === MilestoneType.Error) {
          warnIcon = <ErrorIcon style={{ color: ColorHexValue.Gray }} />;
        }
        // Get the color
        let color = 'blue';
        if (trackingEvent.summaryCode === 'COMPLETED') {
          color = 'green';
        } else if (trackingEvent.milestone === MilestoneType.Error) {
          color = 'red';
        }
        // Update the 'to' index of the show/hide button if required
        if (lastShowHide !== -1) {
          allEventsAndButtons[lastShowHide].value = `${allEventsAndButtons[lastShowHide].value}:${allEventsIndex - 1}`;
        }
        // Reset this to start a new button the next time a non-milestone event is encountered
        lastShowHide = -1;
        // Add the event
        allEventsAndButtons.push({
          index: allEventsIndex,
          type: TrackingUpdatesTimelineType.Milestone,
          dateTimeString: dateTimeStr,
          color,
          isMostRecentEvent: isFirst,
          value: trackingEvent,
          title: isError(trackingEvent) ? trackingEvent.detailCode : trackingEvent.summaryCode,
          titleDescription: isError(trackingEvent)
            ? trackingEvent.detailCodeDescription
            : trackingEvent.summaryCodeDescription,
        });
        isFirst = false;
        showingIndexSet[`${allEventsIndex}`] = true;
        allEventsIndex++;
      }
    }
    if (lastShowHide !== -1) {
      // Update the 'to' index of the show/hide button
      allEventsAndButtons[lastShowHide].value = `${allEventsAndButtons[lastShowHide].value}:${allEventsIndex - 1}`;
    }
  }
  // itemsToDisplay is what the front end displays. This will be modified with the same function if a button is clicked (after updating the showingIndexSet)
  const itemsToDisplay = getItemsToDisplay(allEventsAndButtons, showingIndexSet);
  return {
    itemsToDisplay: itemsToDisplay,
    allEventsAndButtons: allEventsAndButtons,
    showingIndexSet: showingIndexSet,
    warnIcon: warnIcon,
  };
}

/**
 * Check whether a given tracking event is an error/exception type
 *
 * @param trackingEvent The tracking event to check
 * @returns True if the given tracking event is an error/exception type, false o.w.
 */
function isError(trackingEvent) {
  return trackingEvent.milestone === MilestoneType.Error;
}

/**
 * Get the list of items to show on the UI, based on expanded sections
 *
 * @param allEventsAndButtons A list of all events and buttons to display on the tracking updates control
 * @param showingIndexSet The list of the events (which can be hidden) which should be shown
 * @returns The items to display
 */
export function getItemsToDisplay(allEventsAndButtons, showingIndexSet) {
  const itemsToDisplay = [];
  for (let index = 0; index < allEventsAndButtons.length; index++) {
    if (showingIndexSet[index]) {
      itemsToDisplay.push(allEventsAndButtons[index]);
    }
  }
  return itemsToDisplay;
}
